import cls from 'classnames';
import { FC } from 'react';
import { useSelector } from 'react-redux';

import CustomLink from '@/components/custom-link';
import { HOMEPAGE_URLS } from '@/lib/constants';
import { selectGlobalPageTheme } from '@/store/slices/global';
import { NavItemEntry, PageTheme } from '@/types/views/generic';

interface EventNavigationProps {
  navItems: NavItemEntry[];
  currentAsPath: string;
  className?: string;
}

const buttonHoverStyles: Partial<Record<PageTheme, string>> = {
  eds: 'hover:bg-eds-pink hover:text-eds-black',
  esn: 'hover:bg-esn-primary-light hover:text-black',
  els: 'hover:bg-els-gold-dark',
  eair: 'hover:bg-air-lila-light hover:text-air-deepBlue',
};

const EventNavigation: FC<EventNavigationProps> = ({
  navItems,
  currentAsPath,
  className = '',
}) => {
  const pageTheme = useSelector(selectGlobalPageTheme);
  const hoverStyles = pageTheme && buttonHoverStyles[pageTheme];

  return (
    <nav className={className}>
      <ul className="flex">
        {navItems.map((navItem) => {
          const navItemPath = navItem.link.as || navItem.link.href;
          const isHomepage = HOMEPAGE_URLS.includes(navItemPath);

          const getBasePath = (path: string) => path.split('?')[0];

          const isActive = isHomepage
            ? getBasePath(currentAsPath) === navItemPath
            : currentAsPath.includes(navItemPath);

          return (
            <li key={navItem.link.id} className="mr-2 last:mr-0">
              <CustomLink
                {...navItem.link}
                className={cls(
                  `block text-body-2 py-2 px-4 rounded-[20px] hover:bg-opacity-70`,
                  isActive &&
                    'font-semibold teair:bg-air-lila-light tesn:bg-esn-primary tesn:text-black teds:bg-eds-pink teds:text-eds-black tels:bg-els-gold-dark',
                  hoverStyles,
                )}
              >
                {navItem.link.text}
              </CustomLink>
            </li>
          );
        })}
      </ul>
    </nav>
  );
};

export default EventNavigation;
