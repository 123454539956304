import cls from 'classnames';
import dynamic from 'next/dynamic';
import React, { FC, useEffect } from 'react';

import NormalButton from '@/components/button/normal';
import OversizeC2AButton from '@/components/button/oversize-c2a';
import useModal from '@/components/modal/use-modal';
import { getQueryParamValue, isBrowser } from '@/lib/utils';
import {
  LotteryFormButton as LotteryFormButtonType,
  LotteryState,
} from '@/types/views/generic';

const LotteryForm = dynamic(() => import('../form'), { ssr: false });

const FORM_ID_QUERY_PARAM_KEY = 'openFormPotId';

type LotteryFormButtonProps = Omit<LotteryFormButtonType, 'componentType'> & {
  isOversize?: boolean;
};

const LotteryFormButton: FC<LotteryFormButtonProps> = ({
  ctaText,
  lotteryTitle,
  lotteryPot,
  isOversize = false,
  initialLotteryState = LotteryState.Form,
}) => {
  const { isModalOpen, toggleModal, setIsModalOpen } = useModal();

  const onCloseModal = () => setIsModalOpen(false);

  useEffect(() => {
    const potIdFromQueryUrl = getQueryParamValue(FORM_ID_QUERY_PARAM_KEY);

    if (Number(potIdFromQueryUrl) === lotteryPot.potId) {
      toggleModal();
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div>
      {isOversize ? (
        <div onClick={toggleModal}>
          <OversizeC2AButton text={ctaText} />
        </div>
      ) : (
        <NormalButton
          className={cls(
            'tesn:rounded-8 tesn:bg-esn-primary hover:tesn:bg-esn-primary-light tesn:text-black',
          )}
          text={ctaText}
          onClick={toggleModal}
        />
      )}

      {isModalOpen && isBrowser() && (
        <LotteryForm
          lotteryTitle={lotteryTitle}
          lotteryPot={lotteryPot}
          initialLotteryState={initialLotteryState}
          onClose={onCloseModal}
        />
      )}
    </div>
  );
};

export default LotteryFormButton;
