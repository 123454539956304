import { Block } from '@contentful/rich-text-types';
import { Inline, Text } from '@contentful/rich-text-types/dist/types/types';
import { Asset, Entry } from 'contentful';
import * as R from 'ramda';

import { actionsTypeMap } from '@/lib/constants';
import getLotteryV2 from '@/middleware/content-service/get-lottery-v2';
import buildPagePath from '@/middleware/lib/build-page-path';
import {
  LinkActionTypeCms,
  LinkEntryCms,
  LinkTargetCms,
  LotteryFormEntryCms,
} from '@/types/cms';
import {
  Link,
  LotteryFormButton,
  LotteryState,
  TextLink,
} from '@/types/views/generic';
import { SectionEntryLayout } from '@/types/views/sections';

import resolveNavigationTarget from '../lib/resolve-navigation-target';

export const mapUrlToLink = (url: string): Link => {
  const resolvedTarget = resolveNavigationTarget(url);

  return {
    id: url,
    isExternal: resolvedTarget.isExternal,
    href: resolvedTarget.url,
  };
};

export const mapEntryToLink = (entry: Entry<LinkTargetCms>): Link | null => {
  const { slug } = entry.fields;

  if (!slug) return null;

  return {
    id: entry.sys.id,
    isExternal: false,
    href: buildPagePath(entry),
  };
};

export const mapToLinkAction = (action: LinkActionTypeCms): Link | null => {
  const actionType = actionsTypeMap[action];

  return {
    id: actionType,
    as: `#${actionType}`,
    href: `#${actionType}`,
    isExternal: false,
  };
};

export const mapToDownloadLink = (file: Asset): Link | null => ({
  id: file.sys.id,
  isExternal: false,
  href: file.fields.file.url,
  download: true,
});

// Main mapper
export const mapToLink = (
  entry: Entry<LinkEntryCms>,
  layout: SectionEntryLayout,
): Link | null => {
  const { targetPage, targetUrl, newTab, action, file } = entry.fields;

  let link: Link | null = null;

  if (targetPage) {
    link = mapEntryToLink(targetPage);
  } else if (targetUrl) {
    link = mapUrlToLink(targetUrl);
  } else if (action) {
    link = mapToLinkAction(action);
  } else if (file) {
    link = mapToDownloadLink(file);
  }

  return link
    ? {
        ...link,
        isExternal: newTab ?? link.isExternal,
        layout,
      }
    : null;
};

export const mapToLotteryV2Button = async (
  entry: Entry<LinkEntryCms>,
): Promise<LotteryFormButton | null> => {
  const targetPage = entry.fields
    .targetPage as unknown as Entry<LotteryFormEntryCms>;
  const { title, lotteryId, ctaText } = targetPage?.fields;

  if (!title || !lotteryId || !ctaText) {
    return null;
  }

  const lottery = await getLotteryV2(lotteryId);

  if (!lottery) return null;

  return {
    componentType: 'lotteryFormButton',
    ctaText,
    lotteryTitle: title,
    lotteryPot: lottery.defaultPot,
    initialLotteryState: lottery.active
      ? LotteryState.Form
      : LotteryState.Error,
  };
};

// Used when a component needs a text in addition to just the href
export const mapToTextLink = (
  entry: Entry<LinkEntryCms>,
  layout: SectionEntryLayout,
): TextLink | null => {
  const link = mapToLink(entry, layout);

  return link
    ? {
        ...link,
        text: entry?.fields?.displayTitle
          ? entry?.fields?.displayTitle
          : entry?.fields?.title,
      }
    : null;
};

export const mapToEntryInlineHyperlink = (node: Block | Inline) => {
  const text = (node.content[0] as Text)?.value;
  const link = mapUrlToLink(node.data.uri);

  if (!link || !text) return null;

  const textLink: TextLink = { ...link, text };

  return {
    ...R.clone(node),
    data: textLink,
  };
};
