import React, { FC } from 'react';
import { useSelector } from 'react-redux';

import usePlayer from '@/lib/hooks/use-player';
import { useStoreDispatch } from '@/store';
import { selectIsChannelSwitcherOpen } from '@/store/slices/global';
import {
  selectPlayerCurrentChannel,
  selectPlayerIsLoading,
  selectPlayerIsPlaying,
  selectPlayerStations,
  setCurrentChannelId,
} from '@/store/slices/player';

import PlayPauseButton from './play-pause-button';

interface RadioPlayPauseButtonProps {
  isActive?: boolean;
  playIcon?: JSX.Element;
  pauseIcon?: JSX.Element;
  stopPropagation?: boolean;

  /*
    ID of the channel that will be set as current after clicking the button.
    Don't pass the prop if current channel id from store should not change.
  */
  channelId?: string | null;
  className?: string;
  onClick?: () => void;
}

const RadioPlayPauseButton: FC<RadioPlayPauseButtonProps> = ({
  isActive = true,
  playIcon,
  pauseIcon,
  className = '',
  stopPropagation = false,
  channelId,
  onClick,
}) => {
  const player = usePlayer();
  const isPlaying = useSelector(selectPlayerIsPlaying) && isActive;
  const isLoading = useSelector(selectPlayerIsLoading) && isActive;
  const channelSwitcherOpen = useSelector(selectIsChannelSwitcherOpen);
  const currentPlayerChannel = useSelector(selectPlayerCurrentChannel);

  const stations = useSelector(selectPlayerStations);

  const dispatch = useStoreDispatch();

  const playOrPauseChannel = (event: React.MouseEvent) => {
    const isStation = stations.some((elem) => elem.id === channelId);

    stopPropagation && event.stopPropagation();

    if (isPlaying) {
      player?.stop();
    } else {
      player?.play();

      if (onClick) {
        onClick();
      }

      if (channelSwitcherOpen) {
        const oldChannelId = currentPlayerChannel?.id;

        const trigger =
          channelId && isStation
            ? 'inside_switch_station_play'
            : 'inside_switch_channel_play';

        window.dataLayer?.push({
          event: trigger,
          current_channel_id: channelId || '',
          old_channel_id: oldChannelId || '',
        });
      }

      if (channelId) {
        dispatch(setCurrentChannelId(channelId));
      }
    }
  };

  return (
    <PlayPauseButton
      isLoading={isLoading}
      className={className}
      isPlaying={isPlaying}
      onClick={playOrPauseChannel}
      pauseIcon={pauseIcon}
      playIcon={playIcon}
    />
  );
};

export default RadioPlayPauseButton;
