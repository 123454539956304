import { format } from 'date-fns';
import { FC } from 'react';

interface BannerProps {
  location: string;
  title?: string;
  date?: string;
  repeatCount?: number;
  speedInSeconds?: number;
}

const RunningBanner: FC<BannerProps> = ({
  location,
  title,
  date,
  repeatCount = 8,
  speedInSeconds = 40,
}) => {
  const bannerDate = date ? format(new Date(date), 'dd.MM.yyyy') : '';

  const repeatedSpans = Array.from({ length: repeatCount }).map((_, index) => (
    <>
      <li className="flex gap-2 font-syne">
        <p>{title?.toUpperCase()}</p>
        <p>{bannerDate.toUpperCase()}</p>
        <p>{location?.toUpperCase()}</p>
      </li>
    </>
  ));

  return (
    <div className="overflow-hidden tesn:bg-black tair:bg-white flex gap-10 border-t-2 border-t-white">
      <ul
        className="flex gap-10 tesn:text-white teair:text-air-deepBlue py-1 animate-infinite-scroll"
        style={{
          animationDuration: `${speedInSeconds}s`,
        }}
      >
        {repeatedSpans}
      </ul>
    </div>
  );
};

export default RunningBanner;
