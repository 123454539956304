import cls from 'classnames';
import React, { FC } from 'react';

import { ArrowRightCircledIcon } from '@/components/icons/arrow-icon';
import usePageTheme from '@/lib/hooks/use-page-theme';

import { oversizeC2A as styles, textBorder } from './styles';

interface OversizeC2AProps {
  text: string;
}

const OversizeC2AButton: FC<OversizeC2AProps> = ({ text: title }) => {
  const { pageTheme } = usePageTheme();

  return (
    <div className={styles.wrapper}>
      <span
        className={cls(
          styles.title,
          pageTheme === 'esn' ? textBorder.esn : textBorder.DEFAULT,
        )}
      >
        {title}
      </span>
      <span className={styles.rightSide}>
        <span className={styles.iconWrapper}>
          <ArrowRightCircledIcon
            className={styles.icon}
            arrowClassName={styles.iconArrow}
            circleClassName={styles.iconCircle}
          />
        </span>
      </span>
    </div>
  );
};

export default OversizeC2AButton;
