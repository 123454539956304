import { Document } from '@contentful/rich-text-types';
import cls from 'classnames';
import { FC } from 'react';

import { LotteryV2 } from '@/types/views/generic';

import RichTextRenderer from '../../rich-text-renderer';
import LotteryFormButton from '../button';

interface LotteryFormTeaserProps {
  teaserTitle: string;
  text?: Document;
  tag?: string;
  ctaText?: string;
  deadlineText?: string;
  isActiveInTimespan: boolean;
  lottery: LotteryV2;
  className?: string;
}

const LotteryFormTeaser: FC<LotteryFormTeaserProps> = ({
  teaserTitle,
  text,
  tag,
  ctaText,
  deadlineText,
  isActiveInTimespan,
  lottery,
  className = '',
}) => (
  <article
    className={cls(
      'flex flex-col items-start p-10 bg-black-light rounded-4',
      className,
    )}
  >
    {tag && <span className="text-overline text-gray-3 mb-4">{tag}</span>}

    <span className="text-h4 text-pink mb-4 tesn:text-esn-primary">
      {teaserTitle}
    </span>

    {text && <RichTextRenderer className="text-body1 mb-4" body={text} />}

    {deadlineText && <span className="text-overline mb-4">{deadlineText}</span>}

    {isActiveInTimespan && ctaText && (
      <LotteryFormButton
        ctaText={ctaText}
        lotteryTitle={lottery.title}
        lotteryPot={lottery.defaultPot}
      />
    )}
  </article>
);

export default LotteryFormTeaser;
